import React, { useEffect, useState, useRef } from "react";
import Membership from "../components/Home/Membership";
import qrCode from "../assets/images/qrcode.png";
import QRCode from "react-qr-code";
import { fetchClient } from "../axios-config";
import { useAuth0 } from "@auth0/auth0-react";
import moment from "moment";
import logo from "../assets/logo.svg";
import { Link, useLocation } from "react-router-dom";
import QRModal from "../components/QRModal";
import { notifyInfo } from "../utils/notifyToast";
import Loader from "../components/Modal/Loader";
import NewWindow from "react-new-window";
import language from "../Json/locale.json";
import useStorage from "../hooks/useStorage";
import "moment-timezone";

const Profile = () => {
  const {
    loginWithRedirect,
    getAccessTokenSilently,
    isAuthenticated,
    isLoading,
  } = useAuth0();
  const [selectedTab, setSelectedTab] = useState(0);
  const [orderHistory, setOrderHistory] = useState([]);
  const [profileData, setProfileData] = useState();
  const [memberShipData, setMemberShipData] = useState();
  const [loadingTab, setLoadingTab] = useState(false);
  const [loading, setLoading] = useState(false);
  const [spinLoading, setSpinLoading] = useState(false);
  const [openQRModal, setOpenQRModal] = useState(false);
  const [orderNumber, setOrderNumber] = useState();
  const [memberShipLoading, setMemberShipLoading] = useState(false);
  const [openWindow, setOpenWindow] = useState(false);
  const [OpenProfile, setOpenProfile] = useState(false);
  const [amountDue, setAmountDue] = useState("0.00");
  const [bookRentalsList, setBookRentalsList] = useState([]);
  const [orderHistoryLoading, setOrderHistoryLoading] = useState(false);
  const [payLoading, setPayLoading] = useState(false);
  const [printData, setPrintData] = useState();
  const [createdAt, setCreatedAt] = useState("");
  const [purchaseID, setPurchaseID] = useState("");
  const [purchaseNumber, setPurchaseNumber] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState({
    name: "",
    last_name: "",
    mobile: "",
    email: "",
  });
  const location = useLocation();
  const windowRef = useRef();
  const modalOverlayRef = useRef(null);
  const modalRef = useRef(null);
  const locale = useStorage();
  localStorage.setItem("last_visited_url", location.pathname);

  useEffect(() => {
    if (!isLoading)
      if (isAuthenticated) {
        const getProfileDetails = async () => {
          setLoading(true);
          const profileFormData = new FormData();
          profileFormData.append("action", "profileDetails");
          const data = await fetchClient(
            getAccessTokenSilently,
            isAuthenticated,
            "",
            profileFormData,
            false
          );
          if (data) {
            setData(data.data);
            setProfileData(data.data);
            const profile = data.data;
            if (
              !profile.name ||
              !profile.last_name ||
              !profile.mobile ||
              !profile.email
            )
              setOpenProfile(true);
          }
          setLoading(false);
        };
        const getMemberShipDetails = async () => {
          setMemberShipLoading(true);
          const memberShipFormData = new FormData();
          memberShipFormData.append("action", "membershipDetails");
          const data = await fetchClient(
            getAccessTokenSilently,
            isAuthenticated,
            "",
            memberShipFormData,
            false
          );
          console.log(data);
          if (data) setMemberShipData(data.data);
          setMemberShipLoading(false);
        };
        getOrderHistory(currentPage);
        getProfileDetails();
        getMemberShipDetails();
      } else {
        notifyInfo(language.please_login[locale]);
        setTimeout(() => {
          loginWithRedirect();
        }, 2000);
      }
  }, [isAuthenticated, isLoading]);

  useEffect(() => {
    let params = window.location.search.split("?")[1];
    if (params === "bookings") return setSelectedTab(1);
    if (params === "purchases") return setSelectedTab(2);
  }, []);

  useEffect(() => {
    if (openWindow && windowRef.current) {
      setTimeout(() => {
        windowRef.current.window.print();
      }, 300);
    }
  }, [openWindow]);

  const handleChangeData = (input) => {
    setData((prev) => {
      return {
        ...prev,
        [input.name]: input.value,
      };
    });
  };

  const handleSubmitData = async (e) => {
    e.preventDefault();
    setOpenProfile(false);
    setSpinLoading(true);
    const formData = new FormData();
    formData.append("action", "profileUpdate");
    formData.append("name", data.name);
    formData.append("last_name", data.last_name);
    formData.append("email", data.email);
    formData.append("mobile", data.mobile);
    const resData = await fetchClient(
      getAccessTokenSilently,
      isAuthenticated,
      "",
      formData,
      false
    );
    if (resData) {
      setData(resData.data);
      setProfileData(resData.data);
    }
    setSpinLoading(false);
  };

  const statusCheck = (status) => {
    if (status === "0") return language.pending[locale];
    if (status === "1") return language.success[locale];
    if (status === "2") return language.failed[locale];
  };

  const handleTabChange = async (value) => {
    setSelectedTab(value);
    setCurrentPage(1);
    setTotal(0);
    window.history.replaceState(
      null,
      null,
      `${
        value === 2
          ? "?purchases"
          : value === 1
          ? "?bookings"
          : window.location.pathname
      }`
    );
    if (value === 4 || value === 3) {
      setLoadingTab(true);
      const profileFormData = new FormData();
      profileFormData.append(
        "action",
        value === 3 ? "LMScurrentLoans" : "LMSgetFines"
      );
      const data = await fetchClient(
        getAccessTokenSilently,
        isAuthenticated,
        "",
        profileFormData,
        false
      );
      console.log(data);
      if (value === 3) setBookRentalsList(data.data.data);
      else if (data.data.amountDue) setAmountDue(data.data.amountDue);
      setLoadingTab(false);
    }
  };

  const orderHistoryChecking = () => {
    var is_Data = true;
    orderHistory.find((orderData) => {
      if (
        orderData.order_data.length ||
        orderData.spacesOrderData?.length ||
        orderData.membershipOrderData
      ) {
        is_Data = false;
      }
    });
    return is_Data;
  };

  const handlePageChange = (value) => {
    getOrderHistory(value === "back" ? currentPage - 1 : currentPage + 1);
  };

  const getOrderHistory = async (pageNo) => {
    setOrderHistoryLoading(true);
    const orderFormData = new FormData();
    orderFormData.append("action", "howOrderHistory");
    orderFormData.append("pageno", pageNo);
    const data = await fetchClient(
      getAccessTokenSilently,
      isAuthenticated,
      "",
      orderFormData,
      false
    );
    if (data) {
      setOrderHistory(data.data);
      setCurrentPage(data.pagination_data.current_page);
      setTotal(Math.ceil(data.pagination_data.total_records / 10));
    }
    setOrderHistoryLoading(false);
  };

  const handleRenew = async (barcode) => {
    const orderFormData = new FormData();
    orderFormData.append("action", "LMSRenewLoan");
    orderFormData.append("barcode", barcode);
    const data = await fetchClient(
      getAccessTokenSilently,
      isAuthenticated,
      "",
      orderFormData,
      false
    );
    if (data) {
      console.log(data);
    }
  };

  const handlePayFines = async () => {
    setPayLoading(true);
    const formData = new FormData();
    formData.append("action", "createPurchase");
    formData.append("items", { libraryFines: { amount: amountDue } });
    const data = await fetchClient(
      getAccessTokenSilently,
      isAuthenticated,
      "",
      formData,
      false
    );
    if (data) {
      console.log(data);
      initiatePayment(data.purchase_number);
    } else setPayLoading(false);
  };

  const initiatePayment = async (purchaseNumber) => {
    const formData = new FormData();
    formData.append("action", "initiatePayment");
    formData.append("purchase_number", purchaseNumber);
    formData.append(
      "redirect_to",
      `${window.location.origin}/profile?purchases`
    );
    const data = await fetchClient(
      getAccessTokenSilently,
      isAuthenticated,
      "",
      formData,
      false
    );
    if (data) window.location.href = data.payment_link;
  };

  useEffect(() => {
    const modalCl = modalRef.current?.classList;
    const overlayCl = modalOverlayRef.current?.classList;
    if (OpenProfile && modalRef.current && modalOverlayRef.current) {
      overlayCl.remove("hidden");
      setTimeout(() => {
        modalCl.remove("opacity-0");
      }, 100);
    } else {
      modalCl.add("opacity-0");
      setTimeout(() => overlayCl.add("hidden"), 100);
    }
  }, [OpenProfile]);

  const Profile = () => (
    <form onSubmit={(e) => handleSubmitData(e)} className="w-full mt-[30px]">
      <div className="w-full flex flex-col md:flex-row gap-[12px] mb-[12px]">
        <input
          className="px-[24px] py-[16px] rounded-[50px] w-full text-white me-[12px] bg-[#11111180]"
          type="text"
          placeholder={language.first_name[locale]}
          name="name"
          required
          value={data.name}
          onChange={(e) => handleChangeData(e.target)}
        />
        <input
          className="px-[24px] py-[16px] rounded-[50px] w-full text-white me-[12px] bg-[#11111180]"
          type="text"
          placeholder={language.last_name[locale]}
          name="last_name"
          required
          value={data.last_name}
          onChange={(e) => handleChangeData(e.target)}
        />
      </div>
      <div className="w-full flex flex-col md:flex-row gap-[12px]">
        <input
          className="px-[24px] py-[16px] rounded-[50px] w-full text-white me-[12px] bg-[#11111180]"
          type="text"
          placeholder={language.phone[locale]}
          name="mobile"
          required
          readOnly={profileData?.mobile}
          value={data.mobile}
          onChange={(e) => handleChangeData(e.target)}
        />
        <input
          className="px-[24px] py-[16px] rounded-[50px] w-full text-white me-[12px] bg-[#11111180]"
          type="email"
          placeholder={language.email[locale]}
          name="email"
          required
          readOnly={profileData?.email}
          value={data.email}
          onChange={(e) => handleChangeData(e.target)}
        />
      </div>
      {spinLoading ? (
        <div
          role="status"
          className="flex w-full md:w-[700px] justify-center mt-[60px]"
        >
          <svg
            aria-hidden="true"
            class="me-2 w-8 h-8 text-[#616161] animate-spin fill-white"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
          <span class="sr-only">Loading...</span>
        </div>
      ) : (
        <div className="flex w-full md:w-[700px] justify-center mt-[60px]">
          <button
            type="submit"
            className="flex justify-center align-center text-white text-[16px] px-[20px] py-[6px] w-fit gradient-border relative "
          >
            {language.save_changes[locale]}
          </button>
        </div>
      )}
    </form>
  );

  const Pagination = () => (
    <div className="flex items-center justify-center gap-[4rem] mt-[2rem]">
      {currentPage !== 1 && (
        <button
          type="button"
          class="w-[44px] h-[44px] bg-white/30 rounded-full inline-flex items-center justify-center text-gray-400"
          onClick={() => handlePageChange("back")}
        >
          <svg
            class="w-5 text-white"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </button>
      )}
      {currentPage !== total && (
        <button
          type="button"
          class="w-[44px] h-[44px] bg-white/30 rounded-full inline-flex items-center justify-center text-gray-400"
          onClick={() => handlePageChange("next")}
        >
          <svg
            class="w-5 text-white"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </button>
      )}
    </div>
  );

  return (
    <div className="pt-[140px] w-full flex justify-center">
      {isAuthenticated ? (
        <div
          className={`w-11/12 max-w-[1800px] relative ${
            selectedTab === 1 ? "md:w-11/12 max-w-[1800px]" : "md:w-7/12"
          }`}
        >
          <ul className="flex text-[20px] md:text-[32px] gap-[48px] md:gap-[64px] mb-[40px] w-full overflow-scroll md:justify-center">
            {language.profile_tabs[locale].map((tab, idx) => (
              <li
                className={`text-white cursor-pointer text-[20px] pb-[8px] min-w-fit ${
                  selectedTab === idx
                    ? "border-b-[1px] border-white"
                    : "opacity-50"
                }`}
                onClick={() => handleTabChange(idx)}
              >
                {tab}
              </li>
            ))}
          </ul>
          {selectedTab === 0 && (
            <div className="flex flex-col justify-center items-center">
              {/* <img
              src={require("../assets/images/profilePic.png")}
              alt="profile pic"
              className="w-[84px] h-[84px]"
            />
            <p className="text-white text-[16px] opacity-50 mt-[14px]">
              Change profile picture
            </p>
            <img
              src={qrCode}
              alt="profile pic"
              className="my-[48px] w-[212px] h-[212px]"
            /> */}
              {memberShipData &&
                moment(memberShipData?.membership_expiry_date).isSameOrAfter(
                  moment(moment(new Date()).format("YYYY-MM-DD"), "YYYY-MM-DD")
                ) &&
                memberShipData?.isVerified && (
                  <QRCode
                    onClick={() => {
                      setOrderNumber(data.email);
                      setOpenQRModal(true);
                    }}
                    size={256}
                    className="w-[142px] h-[142px] mt-[36px] md:mt-0 bg-white p-[15px] cursor-pointer rounded-[20px]"
                    value={data.email}
                    viewBox={`0 0 256 256`}
                  />
                )}
              {memberShipLoading ? (
                <div className="w-full flex justify-between gap-[10rem] mb-[48px]">
                  <div role="status" class="max-w-sm animate-pulse">
                    <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-2.5"></div>
                    <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5"></div>
                    <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                    <span class="sr-only">Loading...</span>
                  </div>
                  <div role="status" class="max-w-sm animate-pulse">
                    <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-2.5"></div>
                    <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5"></div>
                    <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              ) : (
                <>
                  {memberShipData && (
                    <div className="w-full bg-primaryBg p-[20px] rounded-[13px] mb-[48px] mt-[30px]">
                      <h6 className="text-white text-[17px] font-[600] flex justify-between">
                        <span>{memberShipData.label}</span>{" "}
                        <span>
                          {language.expires_on[locale]} :{" "}
                          {memberShipData.membership_expiry_date}
                        </span>
                      </h6>
                      <Link
                        to="/membership"
                        className="text-[14px] text-[#C69E21] cursor-pointer"
                      >
                        {language.change_membership[locale]}
                      </Link>
                    </div>
                  )}
                </>
              )}
              {loading ? (
                <>
                  <div className="w-full flex justify-between gap-[10rem] mb-[10px]">
                    <div role="status" class="max-w-sm animate-pulse">
                      <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 w-[300px] mb-2.5"></div>
                      <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5"></div>

                      <span class="sr-only">Loading...</span>
                    </div>
                    <div role="status" class="max-w-sm animate-pulse">
                      <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 w-[300px] mb-2.5"></div>
                      <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5"></div>

                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                  <div className="w-full flex justify-between gap-[10rem] mb-[48px]">
                    <div role="status" class="max-w-sm animate-pulse">
                      <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700  w-[300px] mb-2.5"></div>
                      <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5"></div>

                      <span class="sr-only">Loading...</span>
                    </div>
                    <div role="status" class="max-w-sm animate-pulse">
                      <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700  w-[300px] mb-2.5"></div>
                      <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5"></div>

                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                </>
              ) : (
                <>{Profile()}</>
              )}
            </div>
          )}
          {/* {selectedTab === 1 && <Membership showHeader={false} />} */}
          {selectedTab === 1 && (
            <>
              {orderHistoryLoading ? (
                <>
                  {[...Array(7)].map(() => (
                    <div className="w-full flex justify-between gap-[10rem] mb-[48px]">
                      <div role="status" class="max-w-sm animate-pulse">
                        <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-2.5"></div>
                        <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5"></div>
                        <span class="sr-only">Loading...</span>
                      </div>
                      <div
                        role="status"
                        class="space-y-8 animate-pulse md:space-y-0 md:space-x-8 md:flex md:items-center w-[200px] h-[200px]"
                      >
                        <div class="flex justify-center items-center w-[200px] h-[200px] bg-gray-300 rounded dark:bg-gray-700"></div>
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <>
                  {orderHistory.map((orderData, idx) => (
                    <>
                      {orderData.order_data.map((order) => (
                        <div className="w-full bg-primaryBg p-[20px] flex flex-col md:flex-row justify-between rounded-[13px] items-center">
                          <div>
                            <h6 className="w-full md:w-auto text-[17px] text-white font-[600]">
                              {order.package_name} <br />{" "}
                            </h6>
                            <h6 className="text-[17px] text-white font-[600]">
                              {statusCheck(order.status)} <br />
                            </h6>
                            <h6 className="text-[17px] text-white font-[600]">
                              {order.order_number} <br />
                            </h6>
                            <h6 className="text-[17px] text-white font-[600]">
                              {order.date} <br />
                            </h6>
                            <h6 className="text-[17px] text-white font-[600]">
                              {language.adult[locale]} : {order.adult_qty}{" "}
                              <br />
                            </h6>
                            <h6 className="text-[17px] text-white font-[600]">
                              {language.child[locale]} : {order.child_qty}{" "}
                              <br />
                            </h6>
                            {/* {order.status === "1" && (
                              <button
                                className="mt-[1rem] flex justify-center align-center text-white text-[16px] px-[20px] py-[6px] w-fit gradient-border relative"
                                onClick={() => {
                                  setPrintData(order);
                                  setOpenWindow(true);
                                }}
                              >
                                {language.print_receipt[locale]}
                              </button>
                            )} */}
                            {/* {console.log(
                                JSON.parse(
                                  orderData.purchase_data.items.packages.find(
                                    (packages) =>
                                      packages.package_info.id ===
                                      order.package_id
                                  ).package_info.time_slots
                                )
                              )} */}
                            {/* <h6 className="text-[17px] text-white font-[600]">
                                {orderData.purchase_data.items.packages.find(
                                  (package) =>
                                    package.package_info.id === order.package_id
                                )}{" "}
                                <br />
                              </h6> */}
                          </div>
                          <QRCode
                            onClick={() => {
                              setOrderNumber(order.order_number);
                              setOpenQRModal(true);
                            }}
                            size={256}
                            className="w-[142px] h-[142px] mt-[36px] md:mt-0 bg-white p-2 cursor-pointer"
                            value={order.order_number}
                            viewBox={`0 0 256 256`}
                          />
                        </div>
                      ))}
                      {orderData.spacesOrderData.map((space) => (
                        <div className="w-full bg-primaryBg p-[20px] flex flex-col md:flex-row justify-between rounded-[13px] items-center">
                          <div>
                            <h6 className="w-full md:w-auto text-[17px] text-white font-[600]">
                              {space.space_name} <br />{" "}
                            </h6>
                            <h6 className="text-[17px] text-white font-[600]">
                              {space.order_number} <br />
                            </h6>
                            <h6 className="text-[17px] text-white font-[600]">
                              {language.start_time[locale]} :{" "}
                              {space.start_date_timing.split(" ")[0]}{" "}
                              {moment(space.start_date_timing.split(" ")[1], [
                                "HH:mm",
                              ]).format("hh:mm A")}{" "}
                              <br />
                            </h6>
                            <h6 className="text-[17px] text-white font-[600]">
                              {language.end_time[locale]} :{" "}
                              {space.end_date_timing.split(" ")[0]}{" "}
                              {moment(space.end_date_timing.split(" ")[1], [
                                "HH:mm",
                              ]).format("hh:mm A")}
                              <br />
                            </h6>
                            <h6 className="text-[17px] text-white font-[600]">
                              {language.attendees[locale]} :{" "}
                              {space.total_attendees} <br />
                            </h6>
                            {/* <button
                              className="mt-[1rem] flex justify-center align-center text-white text-[16px] px-[20px] py-[6px] w-fit gradient-border relative"
                              onClick={() => {
                                setPrintData(space);
                                setOpenWindow(true);
                              }}
                            >
                              {language.print_receipt[locale]}
                            </button> */}
                          </div>
                          <QRCode
                            onClick={() => {
                              setOrderNumber(space.order_number);
                              setOpenQRModal(true);
                            }}
                            size={256}
                            className="w-[142px] h-[142px] mt-[36px] md:mt-0 bg-white p-2 cursor-pointer"
                            value={space.order_number}
                            viewBox={`0 0 256 256`}
                          />
                        </div>
                      ))}
                      {orderData.membershipOrderData && (
                        <div className="w-full bg-primaryBg p-[20px] flex flex-col md:flex-row justify-between rounded-[13px] items-center">
                          <div>
                            <h6 className="w-full md:w-auto text-[17px] text-white font-[600]">
                              {orderData.membershipOrderData.plan_name} <br />{" "}
                            </h6>
                            <h6 className="text-[17px] text-white font-[600]">
                              {statusCheck(
                                orderData.membershipOrderData.payment_status
                              )}{" "}
                              <br />
                            </h6>
                          </div>
                        </div>
                      )}
                    </>
                  ))}
                  {orderHistoryChecking() && (
                    <h2 className="text-white text-center text-[22px]">
                      {language.no_bookings_found[locale]}
                    </h2>
                  )}
                </>
              )}
              {Pagination()}
            </>
          )}
          {selectedTab === 2 && (
            <>
              {orderHistoryLoading ? (
                <>
                  {[...Array(7)].map(() => (
                    <div className="w-full flex justify-between gap-[10rem] mb-[48px]">
                      <div role="status" class="max-w-sm animate-pulse">
                        <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-2.5"></div>
                        <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5"></div>
                        <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                        <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5"></div>
                        <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                        <span class="sr-only">Loading...</span>
                      </div>
                      <div role="status" class="max-w-sm animate-pulse">
                        <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-2.5"></div>
                        <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5"></div>
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <>
                  {orderHistory.map((purchaseData, idx) => (
                    <>
                      {purchaseData.items_simplified.map((item) => (
                        <div className="w-full bg-primaryBg p-[20px] rounded-[13px] items-center mb-[10px]">
                          <div className="w-full flex justify-between">
                            <h6 className="w-full text-[17px] text-white font-[600]">
                              {purchaseData.purchase_data.purchase_number}{" "}
                              <br />
                            </h6>
                            <h6 className="text-[17px] text-white font-[600]">
                              {statusCheck(purchaseData.purchase_data.status)}{" "}
                              <br />
                            </h6>
                          </div>
                          <div className="flex justify-between">
                            <h6 className="text-[17px] text-white font-[600]">
                              {language.items[locale]}: <br />
                            </h6>
                            <h6 className="text-[17px] text-white font-[600]">
                              {item.cost} AED <br />
                            </h6>
                          </div>
                          <h6 className="w-full text-[17px] text-white font-[600]">
                            {item.type}
                            <br />
                          </h6>
                          <h6 className="w-full text-[17px] text-white font-[600]">
                            {item.name}
                            <br />
                          </h6>
                          <h6 className="w-full text-[17px] text-white font-[600]">
                            {item.description}
                            <br />
                          </h6>
                          {purchaseData.purchase_data.status === "1" && (
                            <button
                              className="mt-[1rem] flex justify-center align-center text-white text-[16px] px-[20px] py-[6px] w-fit gradient-border relative"
                              onClick={() => {
                                setCreatedAt(purchaseData.purchase_data.date_added.split(" ")[0]);
                                setPurchaseID(purchaseData.purchase_data.id);
                                setPurchaseNumber(purchaseData.purchase_data.purchase_number);
                                setPrintData({
                                  ...item,
                                  purchaseNumber: purchaseData.purchase_data.purchase_number,
                                });
                                setOpenWindow(true);
                              }}
                            >
                              {language.print_receipt[locale]}
                            </button>
                          )}
                        </div>
                      ))}
                      {!purchaseData.items_simplified.length && (
                        <h2 className="text-white text-center text-[22px]">
                          {language.no_transcations_found[locale]}
                        </h2>
                      )}
                    </>
                  ))}
                  {!orderHistory.length && (
                    <h2 className="text-white text-center text-[22px]">
                      {language.no_transcations_found[locale]}
                    </h2>
                  )}
                </>
              )}
              {Pagination()}
            </>
          )}
          {selectedTab === 3 && (
            <>
              {loadingTab ? (
                <>
                  {[...Array(7)].map(() => (
                    <div className="w-full flex justify-center gap-[10rem] mb-[48px]">
                      <div role="status" class="max-w-sm animate-pulse">
                        <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 w-[22rem] mb-2.5"></div>
                        <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5"></div>
                        <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                        <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5"></div>
                        <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                        <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5"></div>
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <>
                  {bookRentalsList.length ? (
                    <>
                      {bookRentalsList.map((rental) => (
                        <div className="w-full bg-primaryBg p-[20px] rounded-[13px] items-center mb-[10px]">
                          <div className="w-full flex justify-between">
                            <h6 className="w-full text-[17px] text-white font-[600]">
                              Book Name : {rental.title}
                              <br />
                            </h6>
                          </div>
                          <h6 className="text-[17px] text-white font-[600]">
                            Lent on :{" "}
                            {/* {moment(rental.timeLent.split("T")[0]).format(
                              "DD-MM-YYYY"
                            )} */}
                            {moment
                              .utc(rental.timeLent)
                              .tz("Asia/Dubai")
                              .format("DD-MM-YYYY hh:mm:ss A")}
                            <br />
                          </h6>
                          <h6 className="text-[17px] text-white font-[600]">
                            Due :{" "}
                            {/* {moment(rental.timeDue.split("T")[0]).format(
                              "DD-MM-YYYY"
                            )} */}
                            {moment
                              .utc(rental.timeDue)
                              .tz("Asia/Dubai")
                              .format("DD-MM-YYYY hh:mm:ss A")}
                            <br />
                          </h6>
                          <button
                            className="mt-[1rem] flex justify-center align-center text-white text-[16px] px-[20px] py-[6px] w-fit gradient-border relative"
                            onClick={() => handleRenew(rental.barcode)}
                          >
                            Renew
                          </button>
                        </div>
                      ))}
                    </>
                  ) : (
                    <h2 className="text-white text-center text-[22px] mt-28">
                      {language.no_bookrentals_found[locale]}
                    </h2>
                  )}
                </>
              )}
            </>
          )}
          {selectedTab === 4 && (
            <>
              {loadingTab ? (
                <div className="w-full flex items-center justify-center mt-28">
                  <div role="status" class="max-w-sm animate-pulse">
                    <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700  w-[300px] mb-2.5"></div>
                    <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5"></div>

                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              ) : (
                <div className="w-full flex flex-col items-center justify-center mt-28">
                  <p className="w-full flex justify-center text-white text-2xl font-bold">
                    Amount Due : {amountDue} AED
                  </p>
                  <div className="flex justify-center align-center">
                    {Number(amountDue) > 0 && (
                      <button
                        className="mt-[3rem] flex justify-center align-center text-white text-[16px] px-[20px] py-[6px] rounded-[24px] w-fit border-[1.5px] border-[#FFFFFF33] gradient-border relative"
                        onClick={handlePayFines}
                        disabled={!payLoading}
                      >
                        {!payLoading && (
                          <div
                            role="status"
                            className="flex justify-center h-full items-center"
                          >
                            <svg
                              aria-hidden="true"
                              class="mr-2 mt-[2px] w-5 h-5 text-[#616161] animate-spin fill-white"
                              viewBox="0 0 100 101"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"
                              />
                              <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill"
                              />
                            </svg>
                            <span class="sr-only">Loading...</span>
                          </div>
                        )}{" "}
                        Pay Now
                      </button>
                    )}
                  </div>
                </div>
              )}
            </>
          )}
          {openWindow && (
            <NewWindow ref={windowRef} onUnload={() => setOpenWindow(false)}>
              <div
                style={{
                  width: "300px",
                  margin: "5px auto",
                  paddingLeft: "5px",
                  paddingRight: "5px",
                  textAlign: "center",
                  fontFamily: "monospace",
                  backgroundColor: "white",
                }}
              >
                <img
                  style={{ paddingLeft: "3rem", paddingRight: "3rem" }}
                  src="https://cms.shurooq.gov.ae/uploads/House_of_Wisdom_RGB_removebg_preview_d5eb293302.png"
                />
                
           
                <strong style={{ textAlign: "center" }}>
                  Sharjah Investment and
                </strong>
                <br />
                <strong style={{ textAlign: "center" }}>
                  Development Authority (Shurooq)
                </strong>
                <br /> 

                <strong style={{ textAlign: "center" }}>
                PO BOX : 867
                </strong>

                <br /> 
                <strong style={{ textAlign: "center" }}>
                  TRN: 100394964900003 
                </strong>
                <br />
                <strong style={{ textAlign: "center" }}>
                  Date : {createdAt}
    </strong>
                <h3
                  style={{
                    textAlign: "center",
                    margin: "4px",
                    fontSize: 15,
                    fontWeight: "bold",
                    borderBottom: "1px dashed",
                  }}
                >
                  Tax Invoice
                </h3>


                <strong style={{ textAlign: "center" }}> Invoice : #{purchaseID}</strong>
                <p style={{ textAlign: "center" }}> Purchase: {purchaseNumber}</p>
                {printData?.created_by_name && (
                  <>
                    <span>Staff : {printData?.created_by_name}</span>
                    <br />
                  </>
                )}
                {printData?.created_at && (
                  <>
                    <span>Date : {printData?.created_at}  </span>
                    <br />
                  </>
                )}
                <br />
                <table
                  style={{
                    borderBottom: ".5px dashed",
                    width: "100%",
                  }}
                >
                  <tbody>
                    <tr
                      style={{
                        borderTop: "1px dashed",
                        borderBottom: "1px dashed",
                      }}
                    >
                      <td colSpan="2">
                        <b>Description</b>
                      </td>
                      <td style={{ textAlign: "right" }}>
                        <b>Amount</b>
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>
                        {printData?.space_name || printData?.name} <br />
                        {printData?.total_attendees && (
                          <>
                            Total Attendees:
                            {printData?.total_attendees}
                            <br />
                          </>
                        )}
                        {printData?.duration && (
                          <>
                            Duration:
                            {printData?.duration}
                            <br />
                          </>
                        )}
                        {printData?.description && (
                          <>
                            Description:
                            {printData?.description}
                            <br />
                          </>
                        )}
                        {printData?.start_date_timing && (
                          <>
                            Start Time:
                            {printData?.start_date_timing}
                            <br />
                          </>
                        )}
                        {printData?.end_date_timing && (
                          <>
                            End Time:
                            {printData?.end_date_timing}
                            <br />
                          </>
                        )}
                      </td>
                      <td style={{ whiteSpace: "nowrap", float: "right" }}>
                        {printData?.amount ||
                          printData?.cost.toString() ||
                          printData?.total}{" "}
                        AED
                      </td>
                    </tr>
                    
                    <hr />
                    <hr />

                    <br />
                     
                  

                  <tr style={{ borderTop: "1px dashed" }}>
                    
  <td colSpan="100%">    {Number(printData.amount || printData.cost || printData.total) * 0.05}    AED VAT (5%) Included{" "} </td>
</tr>


<hr />

                    <tr>
                      <td></td>
                      <td>Total </td>
                      <td
                        style={{
                          whiteSpace: "nowrap",
                          float: "right",
                        }}
                      >
                        {printData?.amount ||
                          printData?.cost.toString() ||
                          printData?.total}{" "}
                        AED
                      </td>
                    </tr>
                    <hr />
                  </tbody>
                </table>
                {/* {(userDetails.name || userDetails.mobile) && (
                  <table style={{ marginTop: "10px", marginBottom: "25px" }}>
                    <tbody>
                      {userDetails.name && (
                        <tr>
                          <td colSpan="2">
                            <b>Member Name : </b>
                          </td>
                          <td>
                            <b>{userDetails.name}</b>
                          </td>
                        </tr>
                      )}
                      {userDetails.mobile && (
                        <tr>
                          <td colSpan="2">
                            <b>Phone : </b>
                          </td>
                          <td>
                            <b>{userDetails.mobile}</b>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                )} */}
                <strong style={{ textAlign: "center" }}>Thank You!</strong>
                <br />
                <strong style={{ textAlign: "center" }}>
                  Enjoy your visit
                </strong>
                <br />
                <strong style={{ textAlign: "center" }}>
                  Hope to see you again!
                </strong>
                <br />
                {/* <span>No refunds on purchases made</span>
                <br /> */}
                <strong
                  style={{
                    textAlign: "center",
                    fontSize: 15,
                  }}
                >
                  Refund Policy
                </strong>
                <br />
                <table>
                  <tbody>
                    <tr>
                      <td colSpan="2">
                        * <b>PODS, Events, Workshops: Full</b>
                        <br />
                        <b style={{ marginLeft: "15px" }}>
                          refund if cancelled 2 or more
                        </b>
                        <br />
                        <b style={{ marginLeft: "15px" }}>days in advance.</b>
                        <br />* <b> (There will be a cancellation fee</b>
                        <br />
                        <b style={{ marginLeft: "15px" }}>
                          if any). No refund/credits if
                        </b>
                        <br />
                        <b style={{ marginLeft: "15px" }}>less than 2 days.</b>
                        <br />* <b>Customers have options to take a</b>
                        <br />
                        <b style={{ marginLeft: "15px" }}>refund either in :</b>
                        <br />* <b>Cash (under AED 500 per booking)</b>
                        <br />* <b>Cheque (Issued by Finance</b>
                        <br />
                        <b style={{ marginLeft: "15px" }}>department).</b>
                        <br />* <b>Online bank transfer (Issued by</b>
                        <br />
                        <b style={{ marginLeft: "15px" }}>
                          Finance department).
                        </b>
                        <br />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  {/* {orderData.map((order) => (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "1rem",
                      }}
                    >
                      <QRCode
                        size={50}
                        style={{
                          height: "auto",
                          maxWidth: "100px",
                          width: "100px",
                        }}
                        value={order.order_number}
                        viewBox={`0 0 50 50`}
                      />
                      <div style={{ fontSize: 12 }}>{order.package_name}</div>
                      <div style={{ fontSize: 12 }}>{order.order_number}</div>
                    </div>
                  ))} */}
                  {/* <strong style={{ textAlign: "center" }}>
                    If you enjoyed your visit please leave us a review on
                  </strong> */}
                </div>
              </div>
              <div style={{ pageBreakAfter: "always" }}></div>
            </NewWindow>
          )}
        </div>
      ) : (
        <div className="flex flex-col justify-center items-center h-[400px] gap-[1rem]">
          <h2 className="text-white">{language.redirect_login[locale]}</h2>
          <div role="status" className="flex justify-center items-center">
            <svg
              aria-hidden="true"
              class="me-2 w-8 h-8 text-[#616161] animate-spin fill-white"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      )}
      <QRModal
        openQRModal={openQRModal}
        setOpenQRModal={setOpenQRModal}
        orderNumber={orderNumber}
      />
      <div
        ref={modalOverlayRef}
        className="flex justify-center items-center overflow-x-hidden fixed inset-0 z-50 backdrop-blur-sm hidden bg-black bg-opacity-30"
      >
        <div
          ref={modalRef}
          className="relative w-full h-full md:min-w-[400px] md:min-h-[400px] md:w-auto md:h-auto md:my-6 mx-auto max-w-3xl opacity-0 transition-opacity duration-300"
        >
          <div className="bg-modalBg md:max-h-[90vh] overflow-y-scroll h-auto md:min-h-[400px] px-[24px] pb-[20px] md:px-[30px] md:py-[15px] border-0 md:rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none">
            <div className="sticky top-0 pb-[120px] pt-[130px] md:pt-[20px] md:relative md:pb-0 bg-modalBg md:max-h-[90vh]">
              <div className="flex items-center justify-between mb-[60px] md:mb-[36px] rounded-t">
                <h3 className="text-[20px] md:text-[32px] text-white">
                  {language.complete_profile[locale]}
                </h3>
              </div>
              {Profile()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
